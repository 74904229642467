import React from 'react'
import useKeyPress from '../../hooks/useKeyPress'
import styled from 'styled-components'
import GalleryContext from './GalleryContext'
import cancelIcon from '../../../assets/IconPack/svg/cancel.svg'
import arrowLeftIcon from '../../../assets/IconPack/svg/arrow-left.svg'
import arrowRightIcon from '../../../assets/IconPack/svg/arrow-right.svg'
import Img from 'gatsby-image'
import Spinner from '../Spinner'

const GalleryModalContainer = styled.div`
    display: block;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100%;
    height: calc(var(--vh,1vh) * 100);
    top: 0px;
    z-index: 998;
    left: 0px;
    transition: opacity 0.5s ease;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    position:relative;
    background-color: #00000077;
    width: 100%;
    height:100vh;
    padding:0.5rem;
    margin:auto;
    z-index:998;
    justify-content: center;
    align-items: center;
    outline: none;
    gap:0.8rem;
    overflow: none;
`

const SlideButton = styled.button`
    position: absolute;
    top: 50%;
    transform:translate(0,-50%);
    border:0;
    color: white;
    box-shadow: none;
    cursor: pointer;
    z-index: 999;
    height:100px;
    width: 100px;
    background-repeat: no-repeat;
    background-position:center;
    filter:invert(60%);
    &:hover{
        filter:invert(75%);
    }
    background-size: 35px 35px;
`
const PrevButton = styled(SlideButton)`
    left:0;
    background-image: url(${arrowLeftIcon});
`
const NextButton = styled(SlideButton)`
    right:0;
    background-image: url(${arrowRightIcon});
`

const CloseButton = styled.button`
    background-image: url(${cancelIcon});
    background-repeat: no-repeat;
    filter:invert(60%);
    position: absolute;
    top:1rem;
    right:0;
    height:2rem;
    width: 3.2rem;
    cursor: pointer;
    z-index:999;
    &:hover{
        filter:invert(75%);
    }
`

const ActiveImage = styled(Img)`
    max-width:80%;
    max-height: 95%;
    width: 100%;
    height: 100%;    
    user-select: none;
    opacity: 1;
    z-index: 999;
    cursor: zoom-in;
    background: transparent;
    outline: none;
    transition: all 200ms ease 0s;
`

const Caption = styled.div`
    font-size: smaller;
    color: white;
`

const GalleryModal = ({ onClose = () => { } }) => {
    const [arrowLeftPressed] = useKeyPress('ArrowLeft');
    const [arrowRightPressed] = useKeyPress('ArrowRight');
    const [escapePressed] = useKeyPress('Escape');

    const { images, clickedThumbnailIndex } = React.useContext(GalleryContext)

    const [currentImage, setCurrentImage] = React.useState({ ...images[clickedThumbnailIndex], index: clickedThumbnailIndex })

    const close = React.useCallback((e) => {
        if (e) {
            //stop react synthetic event propagation
            e.stopPropagation();
            // stop native document event propagation
            e.nativeEvent.stopImmediatePropagation();
            //trigger only if the target is the one the handler is defined on
            (e.target === e.currentTarget) && onClose();
        }
        else onClose();
    }, [onClose]);

    const prev = () => {
        const prevIndex = images[currentImage.index - 1] ? currentImage.index - 1 : images.length - 1;
        setCurrentImage({ ...images[prevIndex], index: prevIndex });
    }


    const next = () => {
        const nextIndex = images[currentImage.index + 1] ? currentImage.index + 1 : 0;
        setCurrentImage({ ...images[nextIndex], index: nextIndex });
    }

    //this needs to run only when buttons are pressed. prev,next and close are not actual dependencies
    //suppress the eslint warning about missing deps
    React.useEffect(() => {
        arrowLeftPressed && prev();
        arrowRightPressed && next();
        escapePressed && close();
    }, [arrowLeftPressed, arrowRightPressed, escapePressed])// eslint-disable-line react-hooks/exhaustive-deps  

    return (
        <GalleryModalContainer>
            <PrevButton onClick={prev} />
            <Content onClick={close}>
                <CloseButton onClick={close} />
                {/* {spinnerVisible && <Spinner center={true}/>} */}
                <Spinner center={true}/>
                <ActiveImage
                    imgStyle={{objectFit:"contain"}}
                    fluid={currentImage.modalFluid}
                ></ActiveImage>
                <Caption>{currentImage.title + ' - ' +currentImage.description}</Caption>
            </Content>
            <NextButton onClick={next} />
        </GalleryModalContainer>
    )
}

export default GalleryModal
