import React from "react"
import Gallery from "../components/gallery/Gallery"
import Head from "../components/Head"
import parseContentfulCollections from "../helpers/parseContentfulCollections"
import { graphql } from "gatsby"

import ArtworkSelect from "../components/ArtworkSelect"
import useBreakpoint from "../hooks/useBreakpoint"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import colors from "../styles/colors"
import typography from "../styles/typography"

const ArtworkPageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;

    @media (${breakpoints.tabletLandscapeUp}) {
        padding: 2rem 0;
        display: grid;
        grid-template-columns: 1fr 6fr;
        gap: 1rem;
    }
`

const DropdownWrapper = styled.div`
    position: sticky;
    top: 0.5rem;
    left: 0.5rem;
    height: fit-content;
    z-index: 1;
    border-radius: 5px;
    background-color: white;
    padding: 5px;
    width: fit-content;

    @media (${breakpoints.tabletLandscapeUp}) {
        background: transparent;
        width: 100%;
        top: 5rem;

        &:before {
            display: none;
        }
    }

    &:focus {
        border: 0 !important;
        outline: 0 !important;
        box-shadow: none !important;
    }

    &:before {
        margin-left: 1px;
        font-family: ${typography.fonts.primaryAllCaps};
        content: "Collections:";
        font-size: 1.1rem;
        color: ${colors.secondary};
    }
`

export const query = graphql`
    query {
        allContentfulCollection {
            nodes {
                id
                title
                medium
                gallery {
                    id
                    title
                    description
                    thumbnailFluid: fluid(maxHeight: 350, quality: 70) {
                        ...GatsbyContentfulFluid
                    }
                    modalFluid: fluid(maxWidth: 1500, quality: 100) {
                        ...GatsbyContentfulFluid_noBase64
                    }
                    mobileFluid: fluid(maxHeight: 500, quality: 100) {
                        ...GatsbyContentfulFluid
                    }
                }
            }
        }
    }
`

const Artwork = ({ data }) => {
    const { collections, distinctMedia } = parseContentfulCollections(data)

    const breakpoint = useBreakpoint()
    const [selectExpanded,setSelectExpanded] = React.useState(null)

    React.useLayoutEffect(()=>{
        if(breakpoint && breakpoint.tabletLandscapeUp) setSelectExpanded(true)
        else setSelectExpanded(false)
    },[breakpoint])

    //options to be displayed in the select dropdown or list element (categories)
    //returns an array of objects structured as required by the 'react-select' component to render the element
    const createSelectOptions = React.useCallback(() => {
        return distinctMedia.map(distinctMedium => {
            //filter collections by medium
            const options = collections.flatMap(({ medium, title }, index) => {
                return medium === distinctMedium
                    ? { value: index, label: title }
                    : []
            })

            return { label: distinctMedium, options: options }
        })
    }, [collections, distinctMedia])

    const getDefaultSelection = React.useCallback(() => {
        const firstOption = createSelectOptions()[1]

        //if an option contains an options property itself, then it is just a grouptitle which cannot be selected
        //but its options property contains actual options that can be selected
        const isGroupTitle = option => option.options

        const defaultOption = isGroupTitle(firstOption)
            ? firstOption.options[0]
            : firstOption

        return defaultOption
    }, [createSelectOptions])

    const [activeCollection, setActiveCollection] = React.useState(
        collections[getDefaultSelection().value]
    )

    const scrollToTop = () => {

        // document.html.style.overflow ='hidden'        

        if (typeof window !== "undefined") {
            window.scroll({ top: 0, behavior: "smooth" })
        }
    }

    function handleCollectionSelection(selectedOption) {
        scrollToTop()
        setActiveCollection(collections[selectedOption.value]) //each option has a value equal to its index in the array
    }

    return (
        <>
            <Head title="Artwork" />
            <ArtworkPageContainer>
                <DropdownWrapper>
                    <ArtworkSelect
                        defaultValue={getDefaultSelection()}
                        classNamePrefix="select"
                        onChange={handleCollectionSelection}
                        isClearable={false}
                        isSearchable={false}
                        expanded={selectExpanded}
                        options={createSelectOptions()}
                    ></ArtworkSelect>
                </DropdownWrapper>
                <Gallery images={activeCollection.gallery} />
            </ArtworkPageContainer>
        </>
    )
}

export default Artwork
