import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import colors from '../../styles/colors'
import typography from '../../styles/typography'
import GalleryContext from './GalleryContext'
import Button from '../Button'

const InfoOverlay = styled.div`
    display:block;
    transition: transform 0.3s ease-out;    
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: ${colors.black}aa;    
    transform: ${props => props.$active ? 'translateY(0)' : 'translateY(100%)'};
`
const InfoButton = styled(Button)`
    transition:0.3s linear;
    position: absolute;
    bottom: 15px;
    right:15px;
`

const InfoContent = styled.div`
    padding: 5rem 3rem;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap:1rem;

    @media (${breakpoints.tabletUp}){
        display: none;
    }
`
const InfoContainer = styled.div`  
    @media (${breakpoints.tabletUp}), (orientation:landscape){
        display: none;
    };    
`

const InfoField = styled.p`
    color:white;
    text-align:center;
    font-size: larger;
    font-weight:500;
    font-family: ${typography.fonts.primaryAllCaps};
`

const ThumbnailInfo = ({ index }) => {

    const { images } = React.useContext(GalleryContext)

    const imageTitle = images[index].title
    const imageDescription = images[index].description

    const [active, setActive] = React.useState(false)
    const toggle = React.useCallback((e) => { setActive(active => !active); }, [])

    return (
        <InfoContainer $active={active}>
            <InfoOverlay $active={active}>
                <InfoContent>
                    <InfoField>{imageTitle}</InfoField>
                    <InfoField>{imageDescription}</InfoField>
                </InfoContent>
            </InfoOverlay>
            <InfoButton onClick={toggle} size="small"> {active ? 'Close' : 'Info'} </InfoButton>
        </InfoContainer>
    )
}

export default ThumbnailInfo


