import React from "react"
import ReactSelect from "react-select"
import useBreakpoint from "../hooks/useBreakpoint";
import typography from "../styles/typography"
import colors from '../styles/colors'
import breakpoints from '../styles/breakpoints'

const Select = ({ onInputChange = () => { }, expanded = false, ...props }) => {

    const breakpoint = useBreakpoint();

    const styles = {
        option: (provided, state) => ({
            ...provided,
            paddingLeft: state.isSelected ? '10px' : '0',
            fontFamily: typography.fonts.primaryAllCaps,
            textTransform: 'lowercase',
            fontWeight: 'normal',
            fontSize: '1.1rem',
            marginLeft: "15px",
            marginTop: '-5px',
            backgroundColor: "transparent !important",
            pointer: state.isHovered && "cursor",
            outline: "none",
            color: state.isSelected ? colors.secondaryLight : colors.primary,
        }),
        control: (provided) => ({
            ...provided,
            width: '140px',
            border: "1px solid lightgrey",
            outline: "none",
            display: expanded ? "none" : provided.display,
            flexDirection: !expanded ? "row-reverse" : "initial",
        }),
        menu: (provided) => {
            return ({
                ...provided,
                fontFamily: typography.fonts.primaryAllCaps,
                paddingLeft: expanded ? '0' : '10px',
                border: "none",
                boxShadow: 'none',
                outline: "none",
                position: expanded ? "relative" : provided.position,
                overflowX: "hidden",
                "@media only screen and (min-width: 980px)": {
                    background: breakpoint.tabletLandscapeUp && 'transparent'
                },
                
            })
        },
        menuList: (provided, state) => ({
            ...provided,
            overflowX: "hidden",
        }),
        indicatorSeparator: (provided) => ({
            display: "none",
        }),
        groupHeading: (provided) => ({
            ...provided,
            paddingLeft:'0',
            fontSize: '1.1rem',
            fontFamily: typography.fonts.primaryAllCaps,
            marginBottom: '15px',
            textTransform: 'capitalize',
            color: colors.primary,
            fontWeight: '500'
        }),
        group: (provided) => ({
            ...provided,
            textTransform: 'uppercase',
            marginTop: "10px"
        }),
        valueContainer: (provided) => ({
            ...provided,
            textTransform: 'lowercase',
            padding: '0',
            fontFamily: typography.fonts.primaryAllCaps,
        })
    };

    const selectRef = React.useRef();

    const [menuIsOpen, setMenuIsOpen] = React.useState(expanded);

    React.useLayoutEffect(() => {
        setMenuIsOpen(expanded);
    }, [expanded]);

    const handleInputChange = (options, { action }) => {
        onInputChange();

        if (!expanded && action === "menu-close") {
            setMenuIsOpen(false);
            selectRef.current.blur();
        }
    };

    const handleFocus = () => {
        setMenuIsOpen(true);
        selectRef.current.focus();
    };

    return (
        <ReactSelect
            ref={selectRef}
            {...props}
            styles={styles}
            menuIsOpen={menuIsOpen}
            onFocus={handleFocus}
            onInputChange={handleInputChange}
        />
    );
}

export default Select;
