import React from "react"

const useKeyPress = targetKey => {

    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = React.useState(false);
    const [targetKeyEvent, setTargetKeyEvent] = React.useState({});

    // Add event listeners
    React.useEffect(() => {
        function downHandler(e) {
            if (e.key === targetKey) {
                // If pressed key is our target key then set to true
                setKeyPressed(true);
                setTargetKeyEvent(e);
            };
        };
    
        // If released key is our target key then set to false
        const upHandler = (e) => {
            if (e.key === targetKey) {
                setKeyPressed(false);
                setTargetKeyEvent(e);
            };
        };

        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        }

    }, [targetKey]); // targetKey will never change. Effect only runs on mount and unmount
    
    return [keyPressed, targetKeyEvent];
}

export default useKeyPress;
