const parseContentfulCollections = query => {
    const collections = query.nodes || query.allContentfulCollection.nodes

    const distinctMedia = (() => {
        const distinctMedia = collections.reduce((media, { medium }) => {
            if (!media.includes(medium)) media.push(medium);
            return [...media];
        }, [])

        return distinctMedia;
    })()

    const collectionsByMedium = (() => {
        let result = {};

        for (let medium of distinctMedia) {
            result[medium] = collections.filter(
                collection => collection.medium === medium
            );
        };

        return result;
    })();

    return { collections, distinctMedia, collectionsByMedium };
}

export default parseContentfulCollections