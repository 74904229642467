import React from "react"
import GatsbyImg from "gatsby-image"
import styled from "styled-components"
import breakpoints from '../../styles/breakpoints'
// import colors from '../../styles/breakpoints'
import ThumbnailInfo from './ThumbnailInfo'
import GalleryContext from './GalleryContext'

const ThumbnailContainer = styled.div`
    width:100%;
    height:100%;
    position: relative;
    flex-direction: column;
    display: flex;
    border: ${({ border }) => border && "border: 1px solid black"};
    justify-content: center;
    overflow:hidden;

    border: ${props=>props.$border ? `1px solid black` : `none`};

    &:hover {
        cursor: pointer;
    }
`

const StyledImg = styled(GatsbyImg)`
    transition: transform 0.5s ease-in-out;

    @media screen and (${breakpoints.pcSmallUp}) {
        &:hover {
                transform: scale(1.1);
            }
    }
    
    height:100%;
    width:100%;
`


const Thumbnail = React.memo(({
    id,
    border = false,
    onClick,
    index,
}) => {
    const {images} = React.useContext(GalleryContext)

    const artDirectedFluid = [
        {
            ...images[index].thumbnailFluid,
            media: `(${breakpoints.tabletUp})`
        },
        images[index].mobileFluid,        
    ]    

    return (
        <ThumbnailContainer
            key={id}
            role="button"
            tabIndex={0}
            imageindex={index}
            onClick={onClick}
            $border={border}
        >
            <StyledImg fluid={artDirectedFluid} />
            <ThumbnailInfo index={index}> </ThumbnailInfo>
            
        </ThumbnailContainer>
    )
})

export default Thumbnail
