import React from "react"
import GalleryModal from "./GalleryModal"
import styled from "styled-components"
import GalleryContext from './GalleryContext'
import Thumbnail from './Thumbnail'
import breakpoints from '../../styles/breakpoints'
import useBreakpoint from '../../hooks/useBreakpoint'

const GalleryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5rem;
    align-items: center;
    overflow:auto;

    @media screen and (${breakpoints.tabletUp}){
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: 300px;
    }    
`

const Gallery = ({ images = [], onClick = null }) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [clickedThumbnailIndex, setClickedThumbnailIndex] = React.useState(0);
    const currentBreakpoint = useBreakpoint()

    const openModal = (e, targetIndex) => {
        setClickedThumbnailIndex(targetIndex)
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const handleThumbClick = (e,index)=>{
        if(onClick) onClick(e)
        else if(currentBreakpoint.tabletUp){
            openModal(e, index)
        }
    }

    return (
        <GalleryContext.Provider value={{ images: images, clickedThumbnailIndex: clickedThumbnailIndex }}>
            <GalleryContainer>
                {images.map(({ id, title }, index) => (
                    <Thumbnail
                        index={index}
                        key={id}
                        caption={title}
                        onClick={e => handleThumbClick(e,index)}
                    />
                ))}

                {isModalOpen ? <GalleryModal onClose={closeModal} /> : null}
            </GalleryContainer>
        </GalleryContext.Provider>
    )
}

export default Gallery
